import { PrimaryButton } from 'app/components/common/buttons';
import styled from 'styled-components';
import { COLUMN_ALIGN_START__JUSTIFY_CENTER, COLUMN_CENTER } from 'styles/globalStyles';

export const Wrapper = styled.div`
  ${COLUMN_CENTER}
  min-height:100vh;
  position: relative;
  div {
    z-index: 1;
  }
`;
export const Header = styled.header`
  ${COLUMN_CENTER}
  min-height:80px;
  position: absolute;
  top: 0;
  width: 100%;
  @media (max-width: 1057px) {
    margin-left: 20px;
    ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  }
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 440px) {
    font-size: 24px;
  }
`;
export const InfoCircle = styled.img`
  margin-bottom: 20px;
  border: 4px solid var(--red-panda);
  border-radius: 100%;
  @media (max-width: 440px) {
    width: 40px;
    height: 40px;
  }
`;
export const BackHomeButton = styled(PrimaryButton)`
  width: 268px;
  margin-top: 32px;
  @media (max-width: 440px) {
    width: 164px;
    margin-top: 24px;
  }
`;
export const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  max-width: 392px;
  letter-spacing: -0.02em;
  color: var(--spanish-grey);
  @media (max-width: 440px) {
    font-size: 15px;
    margin-bottom: 0;
    line-height: unset;
  }
`;
export const ExceedDescriptionTimer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-right: 5px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--spanish-grey);
  @media (max-width: 440px) {
    font-size: 15px;
  }
`;
