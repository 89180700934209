/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-empty-function */
import { PayloadAction } from '@reduxjs/toolkit';
import { ClaimAuthStatus, ContainerState } from './types';
import { createSlice } from 'store/toolkit';
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors';

import { homeSaga } from './saga';

// The initial state of the Home container
export const initialState: ContainerState = {
  qrcode: null,
  linkToPhone: null,
  initiatedPublicTest: false,
  developerAddress: '',
  authCode: '',
  claimStatus: { loading: false },
  claimAuthStatus: ClaimAuthStatus.init,
  tokenLimitEnd: null,
  claimAuthErrorMessage: '',
  claimAuthSuccessMessage: '',
  redirectToHomeWhenNoInfoAvailable: true
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setAuthCode(state, action: PayloadAction<string>) {
      state.authCode = action.payload;
    },
    setQrCode(state, action: PayloadAction<string>) {
      state.qrcode = action.payload;
    },
    setLinkToPhone(state, action: PayloadAction<string>) {
      state.linkToPhone = action.payload;
    },
    setDevAddress(state, action: PayloadAction<string>) {
      state.developerAddress = action.payload;
    },
    claimWithAddress() {},
    claimAuth() {},
    setClaimAuthErrorMessage(state, action: PayloadAction<string>) {
      state.claimAuthErrorMessage = action.payload;
    },
    setClaimAuthSuccessMessage(state, action: PayloadAction<string>) {
      state.claimAuthSuccessMessage = action.payload;
    },
    setTokenLimitEnd(state, action: PayloadAction<number>) {
      state.tokenLimitEnd = action.payload;
    },
    setClaimStatus(state, action: PayloadAction<boolean>) {
      state.claimStatus.loading = action.payload;
    },
    setClaimAuthStatus(state, action: PayloadAction<ClaimAuthStatus>) {
      state.claimAuthStatus = action.payload;
    },
    setRedirectToHomeWhenNoInfoAvailable(state, action: PayloadAction<boolean>) {
      state.redirectToHomeWhenNoInfoAvailable = action.payload;
    },
    setInitiatedPublicTest(state) {
      state.initiatedPublicTest = !state.initiatedPublicTest;
    }
  }
});

export const { actions: homeActions, reducer: homeReducer, name: sliceKey } = homeSlice;

export const usehomeSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: homeReducer });
  useInjectSaga({ key: sliceKey, saga: homeSaga });
  return { homeActions };
};
