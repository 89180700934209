import { PrimaryButton } from "app/components/common/buttons";
import styled from "styled-components";
import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_CENTER,
  COLUMN_JUSTIFY_START__ALIGN_CENTER,
  FULL_WIDTH,
} from "styles/globalStyles";

export const Wrapper = styled.div`
  ${COLUMN_JUSTIFY_START__ALIGN_CENTER}
  ${FULL_WIDTH}
  min-height:100vh;
  background-color: var(--river-styx);
  position: relative;
`;
export const DevinLogo = styled.img``;
export const Header = styled.header`
  ${COLUMN_CENTER}
  min-height:80px;
  width: 100%;
  @media (max-width: 1057px) {
    margin-left: 20px;
    ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  }
`;
export const NotFoundImage = styled.img`
  margin-top: 200px;
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  width: 598px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 64px;
`;

export const Description = styled.div`
  margin-top: 24px;
  bottom: 48px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--spanish-grey);
`;
export const BackHomeButton = styled(PrimaryButton)`
  width: 268px;
  margin-top: 48px;
  @media (max-width: 440px) {
    width: 164px;
    margin-top: 24px;
  }
`;
