import styled, { css } from 'styled-components';
import { COLUMN_CENTER, ROW_CENTER } from 'styles/globalStyles';

export const Wrapper = styled.div`
  ${COLUMN_CENTER}
  min-height: 100vh;
`;
export const QrCodeContainer = styled.div`
  ${COLUMN_CENTER}
  background-color: var(--noble-black);
  padding: 64px;
  border-radius: 24px;
  z-index: 1;
  @media (max-width: 440px) {
    display: none;
  }
`;
export const QrCodeImage = styled.img`
  width: 304px;
  height: 304;
`;
export const QrCodeWrapper = styled.div`
  ${COLUMN_CENTER}
  width: 340px;
  height: 340px;
  background: var(--white);
  border: 1px solid var(--azureish-white);
  border-radius: 24px;
`;

export const QrCodePendingTitle = styled.div`
  @media (max-width: 440px) {
    display: none;
  }
`;
export const CardTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: var(--white);
  margin-bottom: 16px;
`;
export const CardDescription = styled.div`
  margin-bottom: 32px;
  font-family: 'Inter';
  font-style: normal;
  max-width: 340px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;

  color: var(--dusty-grey);
`;
export const StepWrapper = styled.div`
  ${ROW_CENTER}
  background: var(--black-grey);
  border: 1px solid var(--dead-pixel);
  border-radius: 24px;
  width: fit-content;
  padding: 8px;
  margin-bottom: 32px;
  @media (max-width: 440px) {
    margin-bottom: 96px;
  }
`;
export const StepStyle = css`
  font-family: 'Inter';
  ${COLUMN_CENTER}
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: var(--white);
  background: var(--chun-li-blue);
  letter-spacing: -0.02em;
`;
export const Step = styled.div`
  ${StepStyle}
  border-radius: 16px;
  padding: 6px 12px;
`;
export const StepCircle = styled.div`
  ${StepStyle}
  border-radius: 100%;
  padding: 11px;
  margin-right: 4px;
`;
export const PhoneWrapper = styled.div`
  display: none;
  @media (max-width: 440px) {
    ${COLUMN_CENTER}
    width:100%;
  }
`;
export const PhoneQrCodeWrapper = styled.div`
  width: 100%;
  ${COLUMN_CENTER}
  padding: 20px;
  border-radius: 24px;
  z-index: 1;
`;
export const PhoneCardTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 16px;
  line-height: 40px;
  letter-spacing: -1.87579px;
  color: var(--white);
`;
export const PhoneCardDescription = styled.div`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: var(--dusty-grey);
`;
