import axiosInstance, { axiosInstanceNotLoggedIn } from 'api/axios';
import { AppPages } from 'app/types';
import jwt_decode from 'jwt-decode';
import { logInCallbackSideEffect, loginSideEffect } from 'authorization/sideEffects';
/* eslint-disable */
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import history from 'router/history';
import { globalActions } from 'store/slice';
import { SessionStorageKeys, storage } from 'store/storage';
import { selectDevAddess, selectQrCode, selectRedirectToHome } from './selectors';
import { homeActions } from './slice';
import { ClaimAuthStatus, JWTencodedData } from './types';

export function* setTokenInHeader() {
  let shouldSendTokenRequest: boolean = true;
  let tokenRequestsCount: number = 0;
  let authToken: string;
  while (shouldSendTokenRequest) {
    if (tokenRequestsCount < 15 && shouldSendTokenRequest) {
      shouldSendTokenRequest = false;
      yield put(homeActions.setClaimAuthStatus(ClaimAuthStatus.error));
    }
    tokenRequestsCount = tokenRequestsCount += 1;
    yield put(homeActions.setClaimAuthStatus(ClaimAuthStatus.redirectLoading));
    try {
      authToken = yield logInCallbackSideEffect();
      shouldSendTokenRequest = false;

      yield put(globalActions.setIsLoggedIn(true));
      storage.sessionStorage.write(SessionStorageKeys.AUTH_ACCESS_TOKEN, authToken);
      yield put(homeActions.setRedirectToHomeWhenNoInfoAvailable(false));
      var params = new URLSearchParams();
      var decodedJWT: JWTencodedData = jwt_decode(authToken);
      const coreID = decodedJWT.sub.split(':')[1];
      params.append('address', coreID);
      let shouldRecallAPI: boolean;
      shouldRecallAPI = true;
      while (shouldRecallAPI) {
        try {
          const request: AxiosResponse = yield call(() =>
            axiosInstance.post('/claimAuthorized', params)
          );
          if (request.status === 200) {
            if (typeof request.data !== 'string') {
              yield put(homeActions.setQrCode(request.data.qrcode));
              yield put(homeActions.setLinkToPhone(request.data.link));
            } else {
              const qrCode: string = yield select(selectQrCode);
              if (request.data === 'PENDING' && !qrCode) {
                yield put(homeActions.setClaimAuthSuccessMessage(request.data));
              }
              if (request.data !== 'PENDING') {
                yield put(homeActions.setClaimAuthSuccessMessage(request.data));
                if (request.data === 'CONFIRME_SUBMITTED') {
                  shouldRecallAPI = false;
                }
              }
            }
            yield delay(5000);
          }
        } catch (error: AxiosError | any) {
          if (error.response?.status === 500) {
            yield put(homeActions.setClaimAuthStatus(ClaimAuthStatus.error));

            if (error.response.data) {
              yield put(homeActions.setClaimAuthErrorMessage(error.response?.data));
            } else {
              yield put(homeActions.setClaimAuthErrorMessage('Something went wrong!'));
            }
            yield delay(10000);
          }
          if (error.response?.status === 400) {
            shouldRecallAPI = false;
            const EXCEEDED_TIME = 540000; // 23 hours and 59 Minutes
            yield put(homeActions.setClaimAuthSuccessMessage(''));
            yield put(homeActions.setQrCode(''));
            if (error.response.data >= EXCEEDED_TIME) {
              // User have just recieved the reward and will be redirected to succcess page
              yield put(homeActions.setClaimAuthStatus(ClaimAuthStatus.success));
              history.push(AppPages.SuccessfulRequest);
            } else {
              yield put(homeActions.setTokenLimitEnd(error.response.data));
              yield put(homeActions.setClaimAuthStatus(ClaimAuthStatus.init));
              history.push(AppPages.Exceeded);
            }
          }
        else{
          yield delay(10000);
        }
        }
      }
    } catch (error) {
      
      yield delay(1000);
    }
  }
}
export function* claimAuth() {
  try {
    yield put(homeActions.setClaimAuthStatus(ClaimAuthStatus.loading));
    yield loginSideEffect();
  } catch (error: any) {
    yield put(homeActions.setClaimAuthStatus(ClaimAuthStatus.init));
    toast(error.message, { type: 'error' });
  }
}
export function* claimWithAddress() {
  const address: string = yield select(selectDevAddess);
  if (address !== '') {
    const formData = new FormData();
    yield formData.append('address', address);
    yield put(homeActions.setRedirectToHomeWhenNoInfoAvailable(false));
    try {
      yield put(homeActions.setClaimStatus(true));

      const request: AxiosResponse = yield axiosInstanceNotLoggedIn.post('/claim', formData);
      if (request.status === 200) {
        history.push(AppPages.SuccessfulRequest);
      }
    } catch (error: AxiosError | any) {
      if (error.response?.status === 429) {
        yield put(homeActions.setTokenLimitEnd(error.response.data));
        history.push(AppPages.Exceeded);
      }
    } finally {
      yield put(homeActions.setClaimStatus(false));
    }
  } else {
    toast('Please enter your Core ID', { type: 'error' });
  }
}
export function* homeSaga() {
  yield takeLatest(homeActions.setAuthCode.type, setTokenInHeader);
  yield takeLatest(homeActions.claimWithAddress.type, claimWithAddress);
  yield takeLatest(homeActions.claimAuth.type, claimAuth);
}
