import axios from "axios";
// import history from "router/history";
// import { SessionStorageKeys, storage } from "store/storage";
// import { toast } from "react-toastify";
import { baseURL } from "app/constants";
const axiosInstance = axios.create({
  baseURL,
});
export const axiosInstanceNotLoggedIn = axios.create({
  baseURL,
});

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = storage.sessionStorage.read(
//       SessionStorageKeys.AUTH_ACCESS_TOKEN
//     );
//     if (token && config.headers) {
//       config.headers.authorization = `Bearer ${token}`;
//     } else {
//       toast("Please login!", { type: "error" });
//       history.replace("/");
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

export default axiosInstance;
