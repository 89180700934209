import React from 'react';

import { Helmet } from 'react-helmet-async';
import { BackHomeButton, Description, Header, InfoCircle, Title, Wrapper } from './ErrorPage.style';
import DevinLogoImage from 'assets/svg/devin_logo.svg';
import forbidden from 'assets/svg/forbidden.svg';
import BackgroundImages from 'app/components/common/styles';
import { DevinLogo } from '../../styles';
import { useSelector } from 'react-redux';
import { claimAuthStatus, claimAuthErrorMessage } from '../../selectors';
import { ClaimAuthStatus } from '../../types';
import { CircularProgress } from '@mui/material';
import history from 'router/history';
import { AppPages } from 'app/types';
function ErrorPage() {
  const claimAuthErrorMsg = useSelector(claimAuthErrorMessage);
  const claimAuthState = useSelector(claimAuthStatus);

  return (
    <>
      <Helmet>
        <title>{claimAuthErrorMsg}</title>
        <meta name="description" content="Description of ExceededRequest" />
      </Helmet>
      <Wrapper>
        <InfoCircle src={forbidden}></InfoCircle>
        <Header>
          <DevinLogo src={DevinLogoImage} />
        </Header>
        <Title>Something went wrong...</Title>
        <Description>
          It seems like you have not completed your document digitization and thus are not verified.
          Please complete your document digitization first and try again.
        </Description>
        <BackHomeButton
          disabled={claimAuthState === ClaimAuthStatus.loading}
          onClick={() => {
            history.push(AppPages.ConfirmPublicTest);
          }}>
          {claimAuthState === ClaimAuthStatus.loading ? (
            <CircularProgress sx={{ color: 'var(--white)' }} size={28} />
          ) : (
            'Restart test'
          )}
        </BackHomeButton>
        <BackgroundImages />
      </Wrapper>
    </>
  );
}

export default ErrorPage;
