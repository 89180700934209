/**
 *
 * Home
 *
 */

import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'store/redux-injectors';
import { homeActions, homeReducer, sliceKey } from './slice';
import { homeSaga } from './saga';
import {
  CardsWrapper,
  Description,
  DevinLogo,
  EmptyDiv,
  HomeHeader,
  MobileWrapper,
  Title,
  DesktopWrapper
} from './styles';
import DevinLogoImage from 'assets/svg/devin_logo.svg';
import MobileTabs from './components/MobileTab';
import CorePassPublicTest from './components/CorePassPublicTest';
import DeveloperHub from './components/DeveloperHub';
import BackgroundImages from 'app/components/common/styles';
import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { SessionStorageKeys, storage } from 'store/storage';
import { globalActions } from 'store/slice';
import {
  claimAuthErrorMessage,
  claimAuthStatus,
  selectQrCode,
  selectSuccessMessage
} from './selectors';
import { ClaimAuthStatus } from './types';
import DataRequestInProgress from './components/DataRequestInProgress';
import ErrorPage from './components/ErrorPage/ErrorPage';
import QrCodePage from './components/qrcodePage';

export function Home() {
  useInjectReducer({ key: sliceKey, reducer: homeReducer });
  useInjectSaga({ key: sliceKey, saga: homeSaga });
  const [searchParams] = useSearchParams();
  const claimAuthState: ClaimAuthStatus = useSelector(claimAuthStatus);
  const claimAuthErrorMsg: string = useSelector(claimAuthErrorMessage);
  const QR_Code_src = useSelector(selectQrCode);
  const claimAuthSuccessMsg = useSelector(selectSuccessMessage);
  const dispatch = useDispatch();
  useEffect(() => {
    if (storage.sessionStorage.read(SessionStorageKeys.AUTH_ACCESS_TOKEN) === null) {
      const authCode = searchParams.get('code');
      if (authCode) dispatch(homeActions.setAuthCode(authCode));
    } else {
      dispatch(globalActions.setIsLoggedIn(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (QR_Code_src && !claimAuthSuccessMsg) {
    return <QrCodePage />;
  }
  if (claimAuthSuccessMsg !== '') {
    return <DataRequestInProgress />;
  }
  if (claimAuthState === ClaimAuthStatus.redirectLoading) {
    return <DataRequestInProgress />;
  }
  if (claimAuthErrorMsg !== '' && claimAuthState === ClaimAuthStatus.error) {
    return <ErrorPage />;
  } else {
    return (
      <>
        <Helmet
          meta={[
            { name: 'Devin Faucet', content: 'Claim Test XAB and TCTN' },
            { property: 'title', content: 'Devin Faucet' },
            {
              name: 'description',
              content:
                'Free Test CTN and XAB faucet, claim free test coin and tokens every 24 hours'
            },
            {
              name: 'description',
              content:
                'Free Test CTN and XAB faucet, claim free test coin and tokens every 24 hours'
            },
            {
              name: 'keywords',
              content: 'Devin, Devin faucet, Test CTN, Test XAB, CorePass, Core Blockchain'
            },
            { name: 'language', content: 'English' },
            { property: 'og:url', content: 'https://devin.energy' },
            { property: 'og:type', content: 'website' },
            {
              property: 'og:description',
              content:
                'Free Test CTN and XAB faucet, claim free test coin and tokens every 24 hours'
            },
            {
              property: 'og:image',
              content: 'https://www.linkpicture.com/q/Devin.svg'
            },
            {
              property: 'twitter:card',
              content: 'summary'
            },
            {
              property: 'twitter:url',
              content: 'https://devin.energy'
            },
            {
              property: 'twitter:title',
              content: 'Devin Faucet - Core-Blockchain based faucet'
            },
            {
              property: 'twitter:description',
              content:
                'Free Test CTN and XAB faucet, claim free test coin and tokens every 24 hours'
            },
            {
              property: 'twitter:image',
              content: 'https://www.linkpicture.com/q/Devin.svg'
            }
          ].concat()}>
          <title>Devin Faucet</title>
        </Helmet>
        <>
          <DeskTop />
          <Mobile />
        </>
      </>
    );
  }
}
const DeskTop = () => {
  return (
    <DesktopWrapper>
      <HomeHeader>
        <DevinLogo src={DevinLogoImage} />
      </HomeHeader>
      <Title>Welcome to the official testnet of Core Blockchain</Title>
      <Description>
        All tests and Dapps that are being developed are done on this network.
      </Description>
      <CardsWrapper>
        <CorePassPublicTest />
        <DeveloperHub />
      </CardsWrapper>
      <BackgroundImages />
      <EmptyDiv />
    </DesktopWrapper>
  );
};
const Mobile = () => {
  return (
    <MobileWrapper>
      <HomeHeader>
        <DevinLogo src={DevinLogoImage} />
      </HomeHeader>
      <Title>Welcome to the official testnet of Core Blockchain</Title>
      <Description>
        All tests and Dapps that are being developed are done on this network.
      </Description>
      <MobileTabs />
    </MobileWrapper>
  );
};
