import React from 'react';
import styled from 'styled-components';
import { COLUMN_ALIGN_START__JUSTIFY_START, COLUMN_CENTER } from 'styles/globalStyles';
import corepassLogo from 'assets/svg/corepass_logo.svg';
import { SecondaryButton } from 'app/components/common/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { homeActions, homeReducer, sliceKey } from '../Home/slice';
import { claimAuthStatus } from '../Home/selectors';
import { ClaimAuthStatus } from '../Home/types';
import { CircularProgress } from '@mui/material';
import BackgroundImages from 'app/components/common/styles';
import { DevinLogo } from '../Home/styles';
import DevinLogoImage from 'assets/svg/devin_logo.svg';
import { homeSaga } from '../Home/saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
function PublicTestConfirmationCard() {
  useInjectReducer({ key: sliceKey, reducer: homeReducer });
  useInjectSaga({ key: sliceKey, saga: homeSaga });
  const dispatch = useDispatch();
  const claimAuthState = useSelector(claimAuthStatus);
  return (
    <Wrapper>
      <Header>
        <DevinLogo src={DevinLogoImage} />
      </Header>
      <BackgroundImages />
      <Card>
        <Logo src={corepassLogo} />
        <Title>CorePass public test</Title>
        <Description>
          During this test you will be asked to scan 2 QR codes in quick succession using your
          CorePass ID smartphone app. After completion you will be awarded test XAB and TCTN.{' '}
        </Description>
        <Options>
          <OptionsItem>
            You can only participate with a CorePass profile that has at least 1 digitized government
            issued ID document.
          </OptionsItem>
        </Options>
        <Button
          onClick={() => {
            dispatch(homeActions.claimAuth());
          }}>
          {claimAuthState === ClaimAuthStatus.loading ? (
            <CircularProgress sx={{ color: 'var(--white)', marginTop: '-6px' }} size={28} />
          ) : (
            'Start test'
          )}
        </Button>
      </Card>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  ${COLUMN_CENTER}
  min-height:100vh;
`;
const Card = styled.div`
  z-index: 1;
  ${COLUMN_ALIGN_START__JUSTIFY_START}
  width: 468px;
  height: 672px;
  left: 726px;
  top: 204px;
  background: var(--noble-black);
  border-radius: 24px;
  padding: 64px;
`;
const Logo = styled.img``;
const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.02em;
  margin-top: 64px;
  color: var(--white);
`;
const Description = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-top: 16px;
  color: var(--dusty-grey);
`;
const Options = styled.ul`
  padding: 0 27px;
  color: var(--dusty-grey);
  margin-top: 28px;
  margin-bottom: 84px;
`;
const OptionsItem = styled.li`
  font-size: 13px;
  padding-left: 10px;
`;
const Button = styled(SecondaryButton)`
  width: 100%;
`;
const Header = styled.header`
  ${COLUMN_CENTER}
  min-height:80px;
  width: 100%;
  position: absolute;
  top: 0px;
`;

export default PublicTestConfirmationCard;
