import styled from "styled-components";
import CoreCoinLeft from "assets/svg/core_coin1.svg";
import CoreCoinMiddle from "assets/svg/core_coin2.svg";
import CoreCoinRight from "assets/svg/core_coin3.svg";
import React from "react";

function BackgroundImages() {
  return (
    <>
      <FloatingCoreCoinLeft src={CoreCoinLeft} />
      <FloatingCoreCoinMiddle src={CoreCoinMiddle} />
      <FloatingCoreCoinRight src={CoreCoinRight} />
    </>
  );
}
const FloatingCoreCoinLeft = styled.img`
  position: absolute;
  top: 48px;
  left: 0;
  @media (max-width: 1057px) {
    display: none;
  }
`;
const FloatingCoreCoinRight = styled.img`
  position: absolute;
  top: 246px;
  right: 0px;
  @media (max-width: 1310px) {
    display: none;
  }
`;
const FloatingCoreCoinMiddle = styled.img`
  position: absolute;
  top: 307px;
  left: 213px;
  @media (max-width: 1057px) {
    display: none;
  }
`;
export default BackgroundImages;
