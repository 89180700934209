/* eslint-disable react/no-unescaped-entities */
// import styled from "styled-components";
import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  BackHomeButton,
  Description,
  DevinLogo,
  Header,
  NotFoundImage,
  Title,
  Wrapper
} from './styles';
import DevinLogoImage from 'assets/svg/devin_logo.svg';
import image404 from 'assets/svg/404.svg';
import history from 'router/history';
import { AppPages } from 'app/types';
export function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>404 Not Found</title>
        <meta name="description" content="Description of Home" />
      </Helmet>
      <Wrapper>
        <Header>
          <DevinLogo src={DevinLogoImage} />
        </Header>
        <NotFoundImage src={image404}></NotFoundImage>
        <Title>Page not found</Title>
        <Description>Sorry we couldn't find what you were looking for.</Description>
        <BackHomeButton
          onClick={() => {
            history.push(AppPages.HomePage);
          }}>
          Back to home
        </BackHomeButton>
      </Wrapper>
    </>
  );
}
