import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationRequestHandler,
  AuthorizationResponse,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  RedirectRequestHandler,
  Requestor,
  StringMap,
  TokenRequest,
  TokenRequestHandler,
  TokenResponse,
} from "@ting/app-auth";
import { AppPages } from "app/types";
import history from "router/history";
import { QueryStringUtils } from "./QueryStringUtils";

const HYDRA_ADDRESS =
  process.env.REACT_APP_HYDRA_ADDRESS;
const HYDRA_AUDIENCE =
  process.env.REACT_APP_HYDRA_AUDIENCE ;
const HYDRA_CLIENT_ID = process.env.REACT_APP_HYDRA_CLIENT_ID;
if (!HYDRA_ADDRESS || !HYDRA_AUDIENCE || !HYDRA_CLIENT_ID) {
  console.error("HYDRA_ADDRESS, HYDRA_AUDIENCE, HYDRA_CLIENT_ID not set");
  throw new Error("HYDRA_ADDRESS, HYDRA_AUDIENCE, HYDRA_CLIENT_ID not set");
  
}

const settings = {
  authority: HYDRA_ADDRESS,
  audience: HYDRA_AUDIENCE,
  client_id: HYDRA_CLIENT_ID,
  redirect_uri: `${window.location.origin}/login/callback`,
  response_type: "code",
  scope: "openid offline offline_access",
  extras: { prompt: "consent", access_type: "offline" },
};
class Authorizer {
  private notifier: AuthorizationNotifier;
  private authorizationHandler: AuthorizationRequestHandler;
  private tokenHandler: TokenRequestHandler;

  // state
  private configuration: AuthorizationServiceConfiguration | undefined;
  private request: AuthorizationRequest | undefined;
  private code: string | undefined;
  private requestor: Requestor;

  constructor() {
    this.notifier = new AuthorizationNotifier();
    this.authorizationHandler = new RedirectRequestHandler(
      undefined,
      new QueryStringUtils()
    );
    this.requestor = new FetchRequestor();
    this.tokenHandler = new BaseTokenRequestHandler(this.requestor);
    // set notifier to deliver responses
    this.authorizationHandler.setAuthorizationNotifier(this.notifier);
    // set a listener to listen for authorization responses
    this.notifier.setAuthorizationListener((request, response) => {
      if (
        response &&
        response instanceof AuthorizationResponse &&
        request instanceof AuthorizationRequest
      ) {
        this.request = request;
        this.code = response.code;
      }
    });
  }

  fetchServiceConfiguration() {
    return AuthorizationServiceConfiguration.fetchFromIssuer(
      settings.authority,
      this.requestor
    )
      .then((response) => {
        this.configuration = response;
      })
      .catch(() => {
        // ignore
      });
  }

  async makeAuthorizationRequest() {
    const request = new AuthorizationRequest({
      audience: settings.audience,
      client_id: settings.client_id,
      redirect_uri: settings.redirect_uri,
      response_type: settings.response_type,
      scope: settings.scope,
      extras: settings.extras,
    });

    if (this.configuration) {
      this.authorizationHandler.performAuthorizationRequest(
        this.configuration,
        request
      );
    } else {
      /** Try again till the configuration get ready */
      await this.fetchServiceConfiguration();
      setTimeout(() => this.makeAuthorizationRequest(), 1000);
    }
  }
  async makeTokenRequestFromCode() {
    if (!this.configuration || !this.code) {
      console.error("login_required");
      history.push(AppPages.HomePage);
    }

    let extras: StringMap | undefined;
    if (this.request && this.request.internal) {
      extras = {};
      extras.code_verifier = this.request.internal.code_verifier;
    }
    const request = new TokenRequest({
      client_id: settings.client_id,
      redirect_uri: settings.redirect_uri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: this.code,
      refresh_token: undefined,
      extras,
    });

    try {
      if (this.configuration) {
        const tokenResponse = await this.tokenHandler.performTokenRequest(
          this.configuration,
          request
        );
        return this.handleTokenResponse(tokenResponse);
      }
    } catch (error) {
      // do something
    }
  }

  handleTokenResponse = (response: TokenResponse) => {
    return response.idToken;

    // unset code, so we can do refresh token exchanges subsequently
    // this.code = undefined;
  };

  checkForAuthorizationResponse() {
    return this.authorizationHandler.completeAuthorizationRequestIfPossible();
  }
  async signinCallback() {
    if (!this.configuration) {
      await this.fetchServiceConfiguration();
    }
    await this.checkForAuthorizationResponse();
    return this.makeTokenRequestFromCode();
  }
}

export const authorizer = new Authorizer();
export default authorizer;
