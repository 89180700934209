import {
  Card,
  CardDescription,
  CardTitle,
  Devider,
  NoteWrapper,
  Options,
  OptionsItem,
  OrangeSign,
  ParticipateButton,
  WarningCircleIcon,
  WarningText,
} from "../styles";
import WarningCircle from "assets/svg/warning_circle.svg";
import { useSelector } from "react-redux";
import { claimAuthStatus, isClaimLoading } from "../selectors";
import { CircularProgress } from "@mui/material";
import { ClaimAuthStatus } from "../types";
import history from "router/history";
import { AppPages } from "app/types";
function CorePassPublicTest() {
  const claimLoading = useSelector(isClaimLoading);
  const claimAuthState = useSelector(claimAuthStatus);

  const handleOnClick = () => {
    history.push(AppPages.ConfirmPublicTest);
  };
  return (
    <Card>
      <OrangeSign />
      <CardTitle>CorePass public test</CardTitle>
      <CardDescription>
        {
          " Login with CorePass application to claim your XAB and TestCTN.\n In order to be able to participate in this test you need to:"
        }
      </CardDescription>
      <Options>
        <OptionsItem>Download and install the CorePass application</OptionsItem>
        <OptionsItem>Digitize a government-issued ID document</OptionsItem>
        <OptionsItem>Digitize an email address</OptionsItem>
      </Options>
      <Devider />
      <ParticipateButton
        disabled={claimLoading || claimAuthState === ClaimAuthStatus.loading}
        onClick={handleOnClick}
      >
        {claimAuthState === ClaimAuthStatus.loading ? (
          <CircularProgress sx={{ color: "var(--white)" }} size={28} />
        ) : (
          "I want to participate"
        )}
      </ParticipateButton>
      <NoteWrapper>
        <WarningCircleIcon src={WarningCircle} />
        <WarningText>
          Please note that the XAB coins and the Test Core Tokens in the Devin
          Network have no real value and are not tradable on any exchange and
          are not able to be used for anything else other than the CorePass
          public test.
        </WarningText>
      </NoteWrapper>
    </Card>
  );
}

export default CorePassPublicTest;
