import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/types';
import { initialState } from './slice';

const HomeDomains = {
  devAddress: (state: RootState) => state.home?.developerAddress || initialState.developerAddress,
  isClaimLoading: (state: RootState) =>
    state.home?.claimStatus.loading || initialState.claimStatus.loading,
  claimAuthStatus: (state: RootState) =>
    state.home?.claimAuthStatus || initialState.claimAuthStatus,
  claimAuthErrorMessage: (state: RootState) =>
    state.home?.claimAuthErrorMessage || initialState.claimAuthErrorMessage,
  claimAuthSuccessMessage: (state: RootState) =>
    state.home?.claimAuthSuccessMessage || initialState.claimAuthSuccessMessage,
  tokenLimitEnd: (state: RootState) => state.home?.tokenLimitEnd || initialState?.tokenLimitEnd,
  authCode: (state: RootState) => state.home?.authCode || initialState?.authCode,
  redirectToHomePage: (state: RootState) => state.home?.redirectToHomeWhenNoInfoAvailable,
  initiatedPublicTest: (state: RootState) => state.home?.initiatedPublicTest,
  qrcode: (state: RootState) => state.home?.qrcode,
  linkToPhone: (state: RootState) => state.home?.linkToPhone
};

export const selectDevAddess = createSelector([HomeDomains.devAddress], (homeState) => homeState);
export const isClaimLoading = createSelector(
  [HomeDomains.isClaimLoading],
  (homeState) => homeState
);
export const claimAuthStatus = createSelector(
  [HomeDomains.claimAuthStatus],
  (homeState) => homeState
);
export const claimAuthErrorMessage = createSelector(
  [HomeDomains.claimAuthErrorMessage],
  (homeState) => homeState
);
export const tokenLimitEnd = createSelector([HomeDomains.tokenLimitEnd], (homeState) => homeState);
export const selectAuthCode = createSelector([HomeDomains.authCode], (homeState) => homeState);
export const selectRedirectToHome = createSelector(
  [HomeDomains.redirectToHomePage],
  (homeState) => homeState
);
export const selectInitiatedPublicTest = createSelector(
  [HomeDomains.initiatedPublicTest],
  (homeState) => homeState
);
export const selectQrCode = createSelector([HomeDomains.qrcode], (homeState) => homeState);
export const selectSuccessMessage = createSelector(
  [HomeDomains.claimAuthSuccessMessage],
  (homeState) => homeState
);
export const selectLinkToPhone = createSelector(
  [HomeDomains.linkToPhone],
  (homeState) => homeState
);
