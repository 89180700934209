import { PrimaryButton } from "app/components/common/buttons";
import styled from "styled-components";
import {
  COLUMN,
  COLUMN_ALIGN_END__JUSTIFY_CENTER,
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_ALIGN_START__JUSTIFY_START,
  COLUMN_CENTER,
  COLUMN_JUSTIFY_START__ALIGN_CENTER,
  FULL_WIDTH,
  ROW_ALIGN_START__JUSTIFY_CENTER,
  ROW_CENTER,
} from "styles/globalStyles";

export const DesktopWrapper = styled.div`
  ${COLUMN_JUSTIFY_START__ALIGN_CENTER}
  ${FULL_WIDTH}
  min-height:100vh;
  background-color: var(--river-styx);
  position: relative;
  @media (max-width: 1057px) {
    display: none;
  }
  div {
    z-index: 1;
  }
`;
export const ExceedHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  max-width: 773px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 440px) {
    font-size: 24px;
    margin-bottom: 8px;
  }
`;

export const HomeHeader = styled.header`
  ${COLUMN_CENTER}
  min-height:80px;
  width: 100%;
  @media (max-width: 1057px) {
    margin-left: 20px;
    ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  }
`;
export const DevinLogo = styled.img``;
export const Title = styled.div`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  width: 598px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
  margin-top: 120px;
  @media (max-width: 1057px) {
    width: unset;
    margin-top: 48px;
    font-size: 40px;
    margin-bottom: 16px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: start;
  }
`;
export const CardTitle = styled.div`
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  margin-top: 80px;
  @media (max-width: 1057px) {
    margin-top: 0px;
    font-size: 28px;
  }
`;
export const Description = styled.div`
  font-weight: 400;
  font-size: 24px;
  color: var(--dusty-grey);
  line-height: 32px;
  margin: 0 20px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 96px;
  @media (max-width: 1057px) {
    margin-bottom: 45px;
    text-align: start;
    font-size: 15px;
  }
`;
export const CardDescription = styled.div`
  font-weight: 400;
  font-size: 15px;
  width: 512px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-top: 16px;
  color: var(--dusty-grey);
  @media (max-width: 1310px) {
    width: 100%;
  }
`;
export const CardsWrapper = styled.div`
  ${ROW_CENTER}

  @media (max-width: 1310px) {
    width: 100%;
  }
  @media (max-width: 1057px) {
    ${COLUMN_CENTER}
  }
`;
export const Card = styled.div`
  position: relative;
  ${COLUMN_ALIGN_START__JUSTIFY_START}
  width: 632px;
  height: 805px;
  background: var(--noble-black);
  margin: 0 8px;
  border-radius: 24px;
  padding: 64px;
  @media (max-width: 1310px) {
    margin: 0 13px;
    width: 100%;
  }
  @media (max-width: 1057px) {
    margin: 0;
    padding: 20px;
    background: transparent;
  }
`;
export const OrangeSign = styled.div`
  width: 24px;
  height: 4px;
  background: var(--plastic-carrot);
  border-radius: 2px;
  @media (max-width: 1057px) {
    display: none;
  }
`;
export const Options = styled.ul`
  padding: 0 27px;
  color: var(--dusty-grey);
`;
export const OptionsItem = styled.li`
  font-size: 13px;
  margin: 12px 0;
`;
export const Devider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--dead-pixel);
  margin-top: 50px;
  margin-bottom: 50px;
  @media (max-width: 1057px) {
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;
export const ParticipateButton = styled(PrimaryButton)`
  width: 268px;
  @media (max-width: 1057px) {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;
export const NoteWrapper = styled.div`
  border: 1px solid var(--dead-pixel);
  position: absolute;
  bottom: 64px;
  padding: 24px 16px;
  margin-top: 110px;
  width: 504px;
  border-radius: 12px;
  ${ROW_ALIGN_START__JUSTIFY_CENTER}
  @media (max-width: 1310px) {
    width: 80%;
  }
  @media (max-width: 1057px) {
    position: unset;
    width: 100%;
    background-color: var(--noble-black);
    margin-top: 36px;
  }
`;
export const WarningCircleIcon = styled.img`
  margin-top: -5px;
`;
export const WarningText = styled.div`
  font-weight: 400;
  color: var(--dusty-grey);
  margin-left: 15px;
  font-size: 11px;
`;
export const WalletSideNote = styled.div`
  margin-top: 16px;
  font-weight: 400;
  color: var(--dusty-grey);
  width: 100%;
  font-size: 11px;
`;
export const WalletAddressInput = styled.input`
  transition: all 0.3s;
  font-weight: 500;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--title-text);
  font-size: 15px;
  width: 100%;
  text-overflow: ellipsis;
  border: 0;
  outline: 0;
  padding: 10px 16px;
  &:focus {
    outline: none !important;
  }
`;
export const CenterTextDiv = styled.div`
  ${COLUMN_CENTER}
  width:100vw;
  height: 100vh;
`;
export const WalletAddInputWrapper = styled.div`
  ${COLUMN_ALIGN_END__JUSTIFY_CENTER}
  background: var(--black-grey);
  height: 48px;
  z-index: 1;
  width: 100%;
  transition: height 1s ease 0s;
  will-change: height;
  border: 1px solid var(--dead-pixel);
  border-radius: 12px;
  @media (max-width: 1057px) {
    position: unset;
    width: 100%;
    background-color: var(--noble-black);
  }
`;
export const RequestButton = styled(PrimaryButton)`
  min-width: 160px;
  margin-left: 8px;
  @media (max-width: 1057px) {
    width: 100%;
    margin-top: 16px;
  }
`;
export const RequestAndInputWrapper = styled.div`
  margin-top: 48px;
  width: 100%;
  ${ROW_CENTER}
  @media (max-width: 1057px) {
    ${COLUMN}
  }
`;
export const EmptyDiv = styled.div`
  height: 300px;
`;
export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 1057px) {
    ${COLUMN_JUSTIFY_START__ALIGN_CENTER}
    ${FULL_WIDTH}
  }
`;
