import { FC } from "react";
import styled from "styled-components";
import { BUTTON } from "styles/globalStyles";

export interface CustomButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  hasBorder?: boolean;
  disabled?: boolean;
}

export const PrimaryButton: FC<CustomButtonProps> = ({
  children,
  ...props
}) => {
  return <PrimaryButtonStyle {...props}>{children}</PrimaryButtonStyle>;
};
export const SecondaryButton: FC<CustomButtonProps> = ({
  children,
  ...props
}) => {
  return <SecondaryButtonStyle {...props}>{children}</SecondaryButtonStyle>;
};

const SecondaryButtonStyle = styled.button`
  background: var(--chun-li-blue);
  border-radius: 12px;
  transition: all 0.15s;
  height: 44px;
  -webkit-tap-highlight-color: transparent;
  ${BUTTON}
  color: var(--white);
  font-weight: 700;
  &:active {
  }
  &:disabled {
    padding: 7px;
    cursor: default;
  }
`;
const PrimaryButtonStyle = styled.button`
  background: var(--caribbean-green);
  border-radius: 12px;
  transition: all 0.15s;
  height: 44px;
  -webkit-tap-highlight-color: transparent;
  ${BUTTON}
  color: var(--river-styx);
  font-weight: 700;
  &:active {
    background: var(--turquoise-blue);
  }
  &:disabled {
    background: var(--royal-palm);
    padding: 7px;
    cursor: default;
  }
`;
