/* --- STATE --- */
export interface HomeState {
  authCode: string;
  developerAddress: string;
  claimAuthErrorMessage: string;
  claimAuthSuccessMessage: string;
  initiatedPublicTest: boolean;
  redirectToHomeWhenNoInfoAvailable: boolean;
  tokenLimitEnd: null | number;
  qrcode: string | null;
  linkToPhone: string | null;
  claimStatus: {
    loading: boolean;
  };
  claimAuthStatus: ClaimAuthStatus;
}
export interface JWTencodedData {
  at_hash: string;
  aud: string[];
  auth_time: number;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  rat: number;
  sid: string;
  sub: string;
}
export enum ClaimAuthStatus {
  init,
  redirectLoading,
  loading,
  inProgress,
  success,
  error
}
export type ContainerState = HomeState;
