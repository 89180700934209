import React from 'react';
import { DevinLogo } from '../../styles';
import { Header } from '../DataRequestInProgress.style';
import {
  CardDescription,
  CardTitle,
  PhoneCardDescription,
  PhoneCardTitle,
  PhoneQrCodeWrapper,
  PhoneWrapper,
  QrCodeContainer,
  QrCodeImage,
  QrCodeWrapper,
  Step,
  StepCircle,
  StepWrapper,
  Wrapper
} from './styles';
import DevinLogoImage from 'assets/svg/devin_logo.svg';
import smallCheck from 'assets/svg/small_check.svg';
import BackgroundImages from 'app/components/common/styles';
import { useSelector } from 'react-redux';
import { selectLinkToPhone, selectQrCode } from '../../selectors';
import { SecondaryButton } from 'app/components/common/buttons';
import styled from 'styled-components';
import { COLUMN_CENTER } from 'styles/globalStyles';
function QrCodePage() {
  const QR_Code_src = useSelector(selectQrCode);
  const linkToPhone = useSelector(selectLinkToPhone);
  return (
    <Wrapper>
      <Header>
        <DevinLogo src={DevinLogoImage} />
      </Header>
      <BackgroundImages />
      {linkToPhone ? <PhoneCard link={linkToPhone!}></PhoneCard> : <div></div>}
      <QrCodeContainer>
        <StepWrapper>
          <StepCircle>
            <img src={smallCheck} alt="" />
          </StepCircle>
          <Step>Step 2</Step>
        </StepWrapper>
        <CardTitle>Scan QR code #2</CardTitle>
        <CardDescription>
          This scan will trigger a data request that will be sent to your CorePass app.
        </CardDescription>
        <QrCodeWrapper>
          <QrCodeImage src={`data:image/png;base64,${QR_Code_src}`} alt="QR Code" />
        </QrCodeWrapper>
      </QrCodeContainer>
    </Wrapper>
  );
}
const PhoneCard = ({ link }: { link: string }) => {
  return (
    <PhoneWrapper>
      <PhoneQrCodeWrapper>
        <StepWrapper>
          <StepCircle>
            <img src={smallCheck} alt="" />
          </StepCircle>
          <Step>Step 2</Step>
        </StepWrapper>
        <PhoneCardTitle>Click button bellow to continue</PhoneCardTitle>
        <PhoneCardDescription>
          Clicking the button bellow will trigger a data request that will be sent to your CorePass
          app
        </PhoneCardDescription>
        <ButtonAnchor href={link}>
          <Button onClick={() => {}}>Continue</Button>
        </ButtonAnchor>
      </PhoneQrCodeWrapper>
    </PhoneWrapper>
  );
};
const Button = styled(SecondaryButton)`
  width: 100%;
`;
const ButtonAnchor = styled.a`
  width: 100%;
  ${COLUMN_CENTER}
`;

export default QrCodePage;
