import React from 'react';
import { DevinLogo } from '../styles';
import {
  Header,
  Wrapper,
  Description,
  Title,
  SpinnerContainer,
  Spinner
} from './DataRequestInProgress.style';
import DevinLogoImage from 'assets/svg/devin_logo.svg';
import SpinnerImg from 'assets/svg/spinner.svg';
import BackgroundImages from 'app/components/common/styles';
import { selectSuccessMessage } from '../selectors';
import { useSelector } from 'react-redux';
function DataRequestInProgress() {
  const claimAuthSuccessMsg = useSelector(selectSuccessMessage);
  return (
    <Wrapper>
      <Header>
        <DevinLogo src={DevinLogoImage} />
      </Header>
      <SpinnerContainer>
        <Spinner src={SpinnerImg} />
      </SpinnerContainer>
      {claimAuthSuccessMsg ? (
        <Title>{claimAuthSuccessMsg}</Title>
      ) : (
        <Title>Data request is in progress...</Title>
      )}
      <Description>
        {claimAuthSuccessMsg ? '' : 'Please, don’t close your CorePass app.'}
      </Description>
      <BackgroundImages />
    </Wrapper>
  );
}

export default DataRequestInProgress;
