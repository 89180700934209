import styled from 'styled-components';
import { COLUMN_ALIGN_START__JUSTIFY_CENTER, COLUMN_CENTER } from 'styles/globalStyles';

export const Wrapper = styled.div`
  ${COLUMN_CENTER}
  min-height:100vh;
  position: relative;
  div {
    z-index: 1;
  }
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  max-width: 773px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 440px) {
    font-size: 24px;
  }
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-left: 20px;
  margin-right: 20px;
  color: var(--spanish-grey);
  margin-bottom: 48px;
  @media (max-width: 440px) {
    font-size: 15px;
    margin-bottom: 0;
    line-height: unset;
  }
`;
export const Header = styled.header`
  ${COLUMN_CENTER}
  min-height:80px;
  position: absolute;
  top: 0;
  width: 100%;
  @media (max-width: 1057px) {
    margin-left: 20px;
    ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  }
`;
export const SpinnerContainer = styled.div`
  @keyframes rotating {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  animation: rotating 1s linear infinite;
  margin-bottom: 24px;
`;

export const Spinner = styled.img`
  margin-bottom: -4px;
`;
