import { CircularProgress } from "@mui/material";
import WarningCircle from "assets/svg/warning_circle.svg";
import { useDispatch, useSelector } from "react-redux";
import { claimAuthStatus, isClaimLoading } from "../selectors";
import { homeActions } from "../slice";
import {
  Card,
  CardDescription,
  CardTitle,
  NoteWrapper,
  OrangeSign,
  RequestAndInputWrapper,
  RequestButton,
  WalletAddInputWrapper,
  WalletAddressInput,
  WalletSideNote,
  WarningCircleIcon,
  WarningText,
} from "../styles";
import { ClaimAuthStatus } from "../types";
function DeveloperHub() {
  const dispatch = useDispatch();
  const claimLoading = useSelector(isClaimLoading);
  const claimAuthState = useSelector(claimAuthStatus);
  const handleInputOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(homeActions.setDevAddress(e.target.value));
  };
  const handleRequestOnclick = () => {
    dispatch(homeActions.claimWithAddress());
  };
  return (
    <Card>
      <OrangeSign />
      <CardTitle>Developer hub</CardTitle>
      <CardDescription>
        In case you are interested to start or further your Dapp development you
        can claim your XAB test coins here. Claim 1 XAB by simply filling in
        your XAB address.
      </CardDescription>
      <RequestAndInputWrapper>
        <WalletAddInputWrapper>
          <WalletAddressInput
            spellCheck={false}
            placeholder="Enter wallet address ..."
            onChange={handleInputOnchange}
          ></WalletAddressInput>
        </WalletAddInputWrapper>
        <RequestButton
          disabled={claimLoading || claimAuthState === ClaimAuthStatus.loading}
          onClick={handleRequestOnclick}
        >
          {claimLoading ? (
            <CircularProgress sx={{ color: "var(--white)" }} size={28} />
          ) : (
            "Request"
          )}
        </RequestButton>
      </RequestAndInputWrapper>
      <WalletSideNote>
        Please make sure the address is a Devin Network address, which is the
        testnet of Core Blockchain. The address of the Devin Network starts with
        AB.
      </WalletSideNote>
      <NoteWrapper>
        <WarningCircleIcon src={WarningCircle} />
        <WarningText>
          Please note that the XAB coins and the Test Core Tokens in the Devin
          Network have no real value and are not tradable on any exchange and
          are not able to be used for anything else other than the CorePass
          public test.
        </WarningText>
      </NoteWrapper>
    </Card>
  );
}

export default DeveloperHub;
