import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styled from "styled-components";
import CorePassPublicTest from "./CorePassPublicTest";
import DeveloperHub from "./DeveloperHub";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MobileTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledBox sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "var(--dead-pixel)" }}>
        <StyledTabs
          sx={{
            " & button ": { color: "var(--dusty-grey) !important" },
            " & button :hover ": { color: "var(--dusty-grey) !important" },
            " & button :focus ": { color: "var(--dusty-grey) !important" },
            " & button :active ": { color: "var(--dusty-grey) !important" },
            " & button.Mui-selected ": {
              color: "var(--white) !important",
            },
          }}
          value={value}
          onChange={handleChange}
        >
          <StyledTab label="CorePass public test" {...a11yProps(0)} />
          <StyledTab label="Developer hub" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CorePassPublicTest />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DeveloperHub />
      </TabPanel>
    </StyledBox>
  );
}
const StyledTab = styled(Tab)`
  font-family: "Inter" !important;
  text-transform: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px !important;
`;
const StyledTabs = styled(Tabs)`
  .css-1aquho2-MuiTabs-indicator {
    background-color: var(--caribbean-green) !important;
  }
`;
const StyledBox = styled(Box)`
  padding: 1px !important;
`;
